import logo from "./logo.svg";
import "./App.css";
import LandingIllustration from "./components/LandingIllustration/LandingIllustration";
import DescriptionAnimation from "./components/DescriptionAnimation/DescriptionAnimation";
import { Carousel } from "./components/Carousel/Carousel";

function App() {
  return (
    <div className="App">
      <div className="hero">
        <div className="hero-content">
          <div className="logo-container">
            <img className="cruise-logo" src="./cruise-logo.png"></img>

            <p className="hero-desc">Not just another dating app</p>
          </div>

          <div className="hero-cta">
            <button
              className="button"
              onClick={(e) => {
                e.preventDefault();

                window.gtag &&
                  window.gtag("event", "waitlist-click", {
                    app_name: "cruise-landing",
                    screen_name: "Home",
                  });

                window.open("https://form.typeform.com/to/NbeDY5YC", "_blank");
              }}
            >
              <p className="no-margin">Join waitlist</p>
              <p className="button-sub">
                (now boarding the first 999 passengers)
              </p>
            </button>
          </div>
        </div>

        <LandingIllustration />
      </div>
      <div className="content-fuschia full-height flex-center">
        <h1 className="lg">We've all been</h1>
        {/* <h2 style={{ marginTop: -20, marginLeft: "80px" }}>burnt 🔥</h2>
        <p className="font-mynerve color-accent sarcasm">(thanks, tinder)</p> */}
        <Carousel />
      </div>

      {/* <div className="content-fuschia">
        <h1>We've been</h1>
        <h2>stung 🐝</h2>
      </div>

      <div className="content-fuschia">
        <h1>We've been</h1>
        <h2>unhinged 🙅‍♀️</h2>
      </div> */}

      <div className="video-container">
        <video controls autoPlay muted>
          <source src="./reel-mobile.mp4" type="video/mp4" />
        </video>
      </div>

      <div className="footer padding-sm font-inter">
        <p className="font-mynerve" style={{ fontSize: "24px" }}>
          Made with love & kindness in Toronto
        </p>
        <div>
          <p>
            <strong>Follow us for more updates</strong>
          </p>
          <a href="https://www.instagram.com/joincruiseapp/">
            <img width="64px" src="./insta.png"></img>
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
